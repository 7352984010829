// primary colors
$main-blue: #001529;
$medium-blue: #1890ff;
$white: #ffffff;
$black: #000000;



//secondary colors


//basic grey shades



// Status colors

// Font family
$font-family: Mada, Lato, sans-serif, arial;


//media queries
$mobile: 'only screen and (max-width:515px)';
$mobile-small: 'only screen and (max-width:380px)';
$tablet: 'only screen and (max-width:1000px)';
$tablet-big: 'only screen and (max-width:850px)';
$tablet-small: 'only screen and (max-width:650px)';
$desktop: 'only screen and (min-width:1200px)';