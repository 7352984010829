@import "../../styles/variables";


.heading{
    background-image: url(../../img/sky-sun.jpg);
    background-size: cover;
    background-position: center;
    height: 50vh;
}

.text-header{
    max-width: 1200px;
    margin: 0 auto;
    padding: 5% 15px;

    h1{
        margin-bottom: 10px;
        @media #{$tablet-small} {
           font-size: 3.8rem;
           margin-top: 5rem;
        }
        @media #{$mobile} {
            font-size: 2.8rem;
            margin-top: 8rem;
         }
         @media #{$mobile-small} {
            font-size: 2rem;
         }
    }
    h4 {
        @media #{$mobile} {
            font-size: 2rem;
            
         }
         @media #{$mobile-small} {
            font-size: 1.5rem;
         }
    }
    .ant-btn-primary {
        -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    }
    
}