@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap);
.nav {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #001529;
  height: 64px;
  -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3); }
  .nav a {
    padding: 1.5rem;
    font-size: 18px;
    color: #1890ff; }
  .nav a:hover {
    color: #ffffff;
    text-decoration: underline; }
  .nav .active {
    color: #ffffff;
    text-decoration: underline; }
  @media only screen and (max-width: 380px) {
    .nav {
      justify-content: center; } }

.heading {
  background-image: url(/static/media/sky-sun.3db92bef.jpg);
  background-size: cover;
  background-position: center;
  height: 50vh; }

.text-header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5% 15px; }
  .text-header h1 {
    margin-bottom: 10px; }
    @media only screen and (max-width: 650px) {
      .text-header h1 {
        font-size: 3.8rem;
        margin-top: 5rem; } }
    @media only screen and (max-width: 515px) {
      .text-header h1 {
        font-size: 2.8rem;
        margin-top: 8rem; } }
    @media only screen and (max-width: 380px) {
      .text-header h1 {
        font-size: 2rem; } }
  @media only screen and (max-width: 515px) {
    .text-header h4 {
      font-size: 2rem; } }
  @media only screen and (max-width: 380px) {
    .text-header h4 {
      font-size: 1.5rem; } }
  .text-header .ant-btn-primary {
    -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3); }

.about-background {
  background-color: #001529; }
  .about-background .about-container {
    padding: 15px;
    display: flex;
    max-width: 1200px;
    margin: 0 auto; }
    @media only screen and (max-width: 1000px) {
      .about-background .about-container {
        width: 95%; } }
    @media only screen and (max-width: 650px) {
      .about-background .about-container {
        flex-direction: column;
        align-items: center;
        width: 100%; } }
    .about-background .about-container .left {
      display: flex;
      align-items: center;
      padding: 3%; }
      .about-background .about-container .left img {
        width: 180px;
        border-radius: 50%; }
    .about-background .about-container .right {
      padding: 2%; }
      .about-background .about-container .right h2 {
        color: #ffffff;
        margin-bottom: 3px; }
      .about-background .about-container .right p {
        color: #ffffff; }
      @media only screen and (max-width: 1000px) {
        .about-background .about-container .right {
          width: 100%; } }

.protfolio-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px; }
  .protfolio-container .skills {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px; }
  .protfolio-container .skills div {
    border: 1px solid #a7a7a7;
    background-color: #f7f7f7;
    width: 80px;
    text-align: center;
    margin: 5px;
    border-radius: 2px;
    padding: 3px; }
    .protfolio-container .skills div p {
      margin: 0; }
  .protfolio-container h1 {
    text-align: center; }
  .protfolio-container .card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px; }
    @media only screen and (max-width: 850px) {
      .protfolio-container .card-wrapper {
        grid-template-columns: 1fr; } }
  .protfolio-container .ant-card {
    -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3); }

.contact-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 150px;
  max-width: 1200px;
  margin: 0 auto; }
  .contact-container .contact-card {
    background-color: #001529;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3); }
    @media only screen and (max-width: 850px) {
      .contact-container .contact-card {
        flex-direction: column;
        justify-content: center; } }
    @media only screen and (max-width: 515px) {
      .contact-container .contact-card {
        width: 90%; } }
    .contact-container .contact-card .contact-left {
      width: 100%;
      padding: 25px; }
      .contact-container .contact-card .contact-left h2 {
        color: #ffffff;
        margin-bottom: 2px; }
      .contact-container .contact-card .contact-left p {
        color: #ffffff; }
    .contact-container .contact-card .contact-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      padding: 25px; }
      .contact-container .contact-card .contact-right h2 {
        color: #001529; }
    .contact-container .contact-card img {
      width: 40px;
      margin: 0 auto;
      padding: 2px; }


.ant-layout-footer {
  border-top: 1px solid #001529;
  width: 100%;
  margin: 0 auto;
  padding: 24px 15px !important; }

.footer-box, .footer {
  display: flex;
  justify-content: center; }
  .footer-box img, .footer img {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-left: 6px;
    margin-right: 6px; }

html {
  font-size: 62.5%; }

body {
  font-family: 'Mada'; }
  body button:focus {
    outline: none; }

a {
  font-family: 'Noto Sans KR', sans-serif;
  text-decoration: none;
  color: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }

i {
  font-style: italic; }

p {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 1.5rem; }

b {
  font-weight: bold; }

h1 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 4.8rem; }

h2 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 4rem; }

h3 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 3.6rem; }

h4 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 2.4rem; }

h5 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
  font-size: 2rem; }

h6 {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 1.8rem;
  font-weight: 500; }

input {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 1.6rem;
  padding: 10px 15px;
  outline: none;
  height: 35px;
  border-radius: 5px;
  border: none; }

textarea {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 1.6rem;
  padding: 10px 15px;
  margin: 10px 0 0;
  outline: none;
  border-radius: 5px; }

select {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 1.6rem;
  border-radius: 5px; }

hr {
  width: calc(100% + 20px);
  margin-left: -10px;
  border: none;
  border-top-width: 1px;
  border-top-style: solid; }

.display-none {
  display: none; }

.tab:hover {
  cursor: pointer; }

.dropdown {
  font-size: 12px; }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

