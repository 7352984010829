@import "../../styles//variables";

.protfolio-container {
    max-width: 1200px;
    margin: 0 auto;
.skills{
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
    
}
  .skills div {
    border: 1px solid #a7a7a7;
    background-color: #f7f7f7;
    width: 80px;
    text-align: center;
    margin: 5px;
    border-radius: 2px;
    padding: 3px;
    p {
      margin: 0;
    }
  }
  padding: 25px;
  h1 {
    text-align: center;
  }
  .card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media #{$tablet-big} {
      grid-template-columns: 1fr;
    }
  }

  .ant-card {
    -webkit-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  }
}
